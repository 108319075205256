import React from 'react'
import { Link } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import Button from '../components/shared/button'
import {ThemeProvider} from 'styled-components';
import styled from 'styled-components';
import {theme} from '../theme';

const Div = styled.div `
  text-align: center;
`;
const Icon = styled.i `
  font-size: 53px;
  color: #36CFC9;
`;
const Title = styled.h2 `
  white-space: pre-wrap;
`;

const ModalExamplePage = () => (
  <ThemeProvider theme={theme}>
    <ModalRoutingContext.Consumer>

      {({ modal, closeTo }) => (
        <Div>
          {modal ? (
            null
          ) : (
            <header>
              <h1>
                Website https://www.ostrovsky-marketing.ru/
              </h1>
            </header>
          )}
          <Icon className="icon ti-check-box"></Icon>

          <Title>Спасибо!<br/>Ваша заявка отправлена!</Title>
          <Button as={Link} to={closeTo} layout={3}>OK</Button>
        </Div>
      )}

    </ModalRoutingContext.Consumer>
  </ThemeProvider>
)

export default ModalExamplePage